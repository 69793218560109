<template>
    <div class="docs" v-html="md2html" v-highlight></div>
</template>

<script>
    import marked from "marked";
    import hljs from "highlight.js";
    import "highlight.js/styles/github.css";
    import md from "../../assets/README.md";
    marked.setOptions({
        renderer: new marked.Renderer(),
        highlight: function(code) {
            return hljs.highlightAuto(code).value;
        },
        pedantic: false,
        gfm: false,
        tables: true,
        breaks: false,
        sanitize: false,
        smartLists: true,
        smartypants: false,
        xhtml: true
    });
    export default {
        name: "Docs",
        data() {
            return {
                md: md
            };
        },
        computed: {
            md2html() {
                return marked(this.md).replace(/<pre>/g, "<pre class='hljs'>");
            }
        }
        // created() {
        //     this.query();
        // },
        // methods: {
        //     query() {
        //         fetch("/api/md/README.md")
        //             .then(res => {
        //                 console.log(res);
        //                 if (res.status === 200) {
        //                     return res.text();
        //                 }
        //             })
        //             .then(res => {
        //                 this.md = res || "";
        //             })
        //             .catch(err => {
        //                 console.error(err);
        //             });
        //     }
        // }
    };
</script>

<style lang="less" scoped>
    .docs {
        max-width: 1200px;
        margin: 50px auto;
        padding: 20px 50px;
        border: 1px solid #e1e4e8;
        border-radius: 10px;
    }
</style>
