import { render, staticRenderFns } from "./Index.vue?vue&type=template&id=74ede55e&scoped=true&style=background%3A%20%23edeef3%3B"
var script = {}
import style0 from "./Index.vue?vue&type=style&index=0&id=74ede55e&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74ede55e",
  null
  
)

export default component.exports